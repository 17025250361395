.term-main {
  // border-radius: 15px;
  // margin-top: 2px;
  // margin-bottom: 3px;
  // border: 2px solid #140a9a;
  background-color: white !important;
  height: 100vh !important;
}
.terms-header {
  background-color: #140a9a;
  color: white;
  font-size: 18px;
  font-weight: 600;
  // border-radius: 10px 10px 0px 0px;
}
.terms-content {
  padding: 19px 20px;
}
.terms-btn-bar {
  background-color: #140a9a;
  padding: 9px 20px;
  // border-radius: 0px 0px 10px 10px;
}
.terms-btn {
  font-size: 0.9em;
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 33px;
  color: white;
  background-color: #140a9a;
  outline: none;
  border: 1px solid #ddd;
  // margin: 0px 5px;
}
.terms-btn:hover,
.terms-btn:focus {
  font-size: 0.9em;
  font-weight: 400;
  border-radius: 3px;
  outline: none;
  border: 1px solid transparent;
  color: white;
  background-color: #ffc82f;
}
.terms-subject {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}
.terms-heading {
  font-size: 20px;
  font-weight: 700;
}
