// .login {
//   min-height: 100vh;
// }

.bg-image {
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 500;
  font-size: 2.6vw;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}
.back-main {
  padding: 100px 120px;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../../../public/assets/img/bg-img.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-header {
  background-color: #002077;
}
.login {
  min-height: 100vh;
}
.login-form {
  border-radius: 10px;
  margin-top: 120px;
}
.login-label {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.login-btn {
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #002077;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
.login-btn:focus,
.login-btn:hover {
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: #ff9900;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
