.heading-bar {
  background-color: #ffc82f;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0px;
  padding-left: 15px;
}
.main-content-login {
  background-color: white !important;
}
.main-content {
  // border: 2px solid #140a9a;
  background-color: white !important;
  height: 100vh !important;
}
.filter-header {
  background-color: #140a9a;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.label {
  margin-left: 3px;
  font-weight: 500;
}
.filter-buttons {
  display: flex;
  justify-content: center;
}
.fltr-btn,
.logoff-btn {
  background-color: #140a9a;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 20px;
  outline: none;
  border-radius: 3px;
}
.fltr-btn:hover,
.logoff-btn:hover {
  background-color: #ffc82f;
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 20px;
  outline: none;
  border-radius: 3px;
}
.fltr-btn:focus,
.logoff-btn:focus {
  background-color: #ffc82f;
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 20px;
  outline: none;
  border-radius: 3px;
  box-shadow: rgba(126, 186, 238, 0.48) 6px 2px 16px 2px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 2px;
}
.form-select {
  text-align-last: center;
  font-weight: 500;
}
// .header-btn-done
.header-btn {
  font-size: 0.7em;
  font-weight: 400;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: white;
  background-color: #140a9a;
  padding: 4px 7px;
}
// .header-btn-done:hover,
// .header-btn-done:focus {
//   font-size: 16px;
//   font-weight: 400;
//   border-radius: 3px;
//   outline: none;
//   border: none;
//   color: white;
//   background-color: #ffc82f;
//   box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
//     rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
// }
.header-btn:focus,
.header-btn:hover {
  font-size: 0.7em;
  font-weight: 400;
  border-radius: 3px;
  outline: none;
  border: none;
  color: white;
  background-color: #ffc82f;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}
.header-btn-icons {
  color: white;
  margin-top: -3px;
  font-size: 1.1em;
}
.fltr-table-head tr th {
  font-size: 12px !important;
  font-weight: bold !important;
}
.fltr-table-body tr td {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.map-div {
  background-color: #140a9a;
  margin-left: 12px;
  margin-right: 12px;
  padding: 0px;
}
.selected-row {
  background-color: #140a9a;
  color: white;
}

.switchToggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
.switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  max-width: 70px;
  height: 30px;
  background: #d1d1d1;
  display: block;
  border-radius: 3px;
  position: relative;
}
.switchToggle label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 3px;
  transition: 0.3s;
}
.switchToggle input:checked + label,
.switchToggle input:checked + input + label {
  background: #3e98d3;
}
.switchToggle input + label:before,
.switchToggle input + input + label:before {
  content: "OFF";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}
.switchToggle input:checked + label:before,
.switchToggle input:checked + input + label:before {
  content: "ON";
  position: absolute;
  top: 5px;
  left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}
.switchToggle input:checked + label:after,
.switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.switchToggle label:active:after {
  width: 60px;
}
.toggle-switchArea {
  margin: 10px 0 10px 0;
}
/*Filter Style collapse*/
.filter-btn {
  border: 1px solid #0994f8;
  background-color: #fff;
  color: #0994f8;
  padding: 7px 15px;
}
.filter-btn:focus {
  border: 1px solid #0994f8 !important;
  background-color: #fff;
  color: #0994f8;
  padding: 7px 15px;
}

.filter-btn:focus {
  outline: none;
  border: none;
}
.open-filter {
  position: absolute;
  z-index: 990;
  background-color: #fff;
  width: 97%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  padding: 20px;
  border-radius: 0px 5px 5px 5px;
}
.abnormalities-table {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}

.map-block-red {
  width: 9%;
  height: 15px;
  background-color: red;
}
.map-block-black {
  width: 9%;
  height: 15px;
  background-color: black;
}
.direction-mode {
  width: 40px;
  height: 33px;
  border: 1px solid #000;
  text-align: center;
  background-color: white;
  cursor: pointer;
}
.select-mode {
  background-color: #ffc82f;
}
